export const environment = {
  production: true,
  encryptPassword: true,
  encryptRequest: false,
  encryptAdminApiRequest:false,
  clientUrl : 'https://prexuat-loans-campaign.arkafincap.com/consumerAPI',
  adminUrl : 'https://prexuat-loans-campaign.arkafincap.com/adminAPI',
  domain : 'https://prexuat-loans-campaign.arkafincap.com',  // Domain on which the application will be deployed
  testUrl: 'https://prexuat-loans-campaign.arkafincap.com/consumerAPI',
  notificationUrl: 'https://prexuat-loans-campaign.arkafincap.com/',
  decisionEngineUrl: 'https://prexuat-loans-campaign.arkafincap.com/decisionEngine',
  videoConferenceUrl: 'wss://identify.sandboxforbank.com/janus/',
  postVideoConferenceUrl: 'https://identify.sandboxforbank.com/api',
  videoRecordingPath: '/home/ubuntu/recording',
  googleApiKey: 'AIzaSyDcXnhJ6s2BSUbyuDuI2rfBKJMH_dJss68',
  loginUrl: 'https://prexuat-auth.arkafincap.com/auth/realms/arka-prod/protocol/openid-connect/auth?client_id=production&response_type=code&state=fj8o3n7bdy1op5&redirect_uri=https://prexuat-loans-campaign.arkafincap.com',
  uamRedirectUrl: 'https://prexuat-loans-campaign.arkafincap.com',
  customerUserPassword: "13232e0442360b3bca1033a3b3c5cc18:1291db1dc2832049f2330408a3a079fa",
  headingFontFamily:'Open Sans',
  contentFontFamily:'SourceSansPro-Regular',
  globalFontSizeContent:'16px',
  immediatePreview: false,
  vishwamSdkUrl: '',
  repaymentUrl: '',
  eid: 'arka-prod',
  repaymentApiKey: '',
  mid: '',
  finvuWebSocketUrl: "wss://webvwdev.finvu.in/consentapi"
};
